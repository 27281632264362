import React,{useEffect, useState} from 'react';
import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import heroImage from './Assets/img/hero-bg.jpg';

import team1 from './Assets/img/team/team-1.jpg'
import user from './Assets/img/testimonials/user.png'

import blog1 from './Assets/img/blog/blog-1.jpg'
import blogauther from './Assets/img/blog/blog-author.jpg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import * as Constant from './Constant';

function App() {
  
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [requirement, setRequirement] = useState("");
  const [phone, setPhone] = useState("");
  const [attachment, setAttachment] = useState("");

  const [fullNameError, setFullNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [numberError, setNumberError] = useState(null);
  const [requirementError, setRequirementError] = useState(null);
  const [attachmentError, setAttachmentError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const faqData = [
    {
      num: 1,
      question: "What types of assignments do you help with?",
      answer: "We assist with a wide range of assignments, including essays, research papers, dissertations, case studies, lab reports, presentations, and more, across various subjects and academic levels."
    },
    {
      num: 2,
      question: "How can I place an order?",
      answer: "Placing an order is easy! Simply fill out our order form with your assignment details, including the topic, deadline, and any specific instructions. Once you submit the form, we will assign the best expert to work on your assignment."
    },
    {
      num: 3,
      question: "How much does it cost to get my assignment done?",
      answer: "Our pricing depends on factors like the assignment type, academic level, deadline, and complexity. We offer affordable rates tailored to students, and you can get an instant quote after providing the assignment details on our website."
    },
    {
      num: 4,
      question: "Will my assignment be original and plagiarism-free?",
      answer: "Yes, all assignments are custom-written according to your specific instructions. We also provide a plagiarism report to ensure that your assignment is 100% original."
    },
    {
      num: 5,
      question: "Can I request revisions if I'm not satisfied with the work?",
      answer: "Absolutely! We offer unlimited revisions free of charge until you are completely satisfied with your assignment."
    }
  ];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if already active
    } else {
      setActiveIndex(index); // Open the clicked item
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(Constant.ContctUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // If response is not OK, throw an error
        const errorData = await response.json();
        throw new Error(errorData.message || 'Something went wrong!');
      }

      const data = await response.json();
      setSuccess(data.message);
      toast.success('Form submitted successfully!');
      
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      setError(error.message || 'Network error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  
  const validate = () => {
    let valid = true;

    if (!fullName) {
      setFullNameError("Please enter full name.");
      valid = false;
    } else {
      setFullNameError(null);
    }

    const reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!email) {
      setEmailError("Please enter email.");
      valid = false;
    } else if (!reg.test(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError(null);
    }

    if (!phone) {
      setNumberError("Please enter number.");
      valid = false;
    } else {
      setNumberError(null);
    }

    if (!requirement) {
      setRequirementError("Please enter your requirement.");
      valid = false;
    } else {
      setRequirementError(null);
    }

    if (!attachment) {
      setAttachmentError("Please attach file.");
      valid = false;
    } else {
      setAttachmentError(null);
    }

    return valid;
  };

  const onSubmit = () => {
    if (validate()) {
      onContact();
    }
  };

  const onContact = async () => {
    setIsLoading(true);
  
    const formData = new FormData();
    formData.append("fullname", fullName);
    formData.append("email", email);
    formData.append("number", phone);
    formData.append("requirement", requirement);
    formData.append("attachment", attachment);
  
    try {
      const response = await fetch(Constant.AssignmentUrl, {
        method: "POST",
        body: formData,
      });
  
      // Ensure response is OK
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
  
        const data = await response.json();
        setFullName("");
        setEmail("");
        setPhone("");
        setRequirement("");
        toast.success('form submitted successfully!');
        
    }  finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="App">

  <header id="header" className="header d-flex align-items-center fixed-top">
    <div className="container-fluid position-relative d-flex align-items-center justify-content-between">

      <a href="index.html" className="logo d-flex align-items-center me-auto me-xl-0">
      {/* <img src="assets/img/logo.png" alt="">  */}
        <h1 className="sitename">Assignment Service Canada</h1>
      </a>

      <nav id="navmenu" className="navbar navbar-expand-lg navbar-light ">
        
  <div className="collapse navbar-collapse" id="navbarNavDropdown">
    <ul className="navbar-nav" >
      <li className="nav-item ">
        <a className="nav-link " href="index.html#hero">Home</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html#about">About</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html#services">Services</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html#recent-posts">Blog</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="index.html#contact">Contact</a>
      </li>
    </ul>
    {/* Mobile nav toggle icon */}
   
  </div>
        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>

      <a className="btn-getstarted" href="index.html#about">Get Started</a>

    </div>
  </header>

  <main className="main">

    <section id="hero" className="contact section hero section dark-background">

    <img
        className="img-fluid"
        src={heroImage}
        alt="blogimg1"
      />

      <div className="container">
        <div className="row">
        <h2 style={{textAlign:'left'}} data-aos="fade-up" data-aos-delay="100">Welcome to Our Website</h2>
        <p style={{textAlign:'left'}} data-aos="fade-up" data-aos-delay="200">We are team of talented designers making websites with Bootstrap</p>
          <div className="col-lg-6">
          <form  className="php-email-form" >
          <div className="row gy-4 mt-4 error" style={{textAlign:'left'}}>
            <div className="col-md-6">
                
                <input 
                type="text" 
                name="fullname"
                style={{borderRadius: '0px'}} 
                className="form-control"
                placeholder="Full Name" 
                onChange={(e) => setFullName(e.target.value)}
                required="" />
                {fullNameError && <span>{fullNameError}</span>}
             </div>
             <div className="col-md-6">
                <input
                  type="email"
                  value={email}
                  style={{borderRadius: '0px'}} 
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                {emailError && <span>{emailError}</span>}
            </div>
            <div className="col-md-12">
            <input
              type="text"
              value={phone}
              style={{borderRadius: '0px'}} 
              className="form-control"
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone"
            />
            {numberError && <span>{numberError}</span>}
            </div>
            <div className="col-md-12">
            <textarea
              value={requirement}
              style={{borderRadius: '0px'}} 
              className="form-control"
              onChange={(e) => setRequirement(e.target.value)}
              placeholder="Your Requirement"
            />
            {requirementError && <span>{requirementError}</span>}
            </div>
            <div className="col-md-12">
            <input
              type="file"
              id="attachment"
              style={{borderRadius: '0px'}} 
              className="form-control"
              onChange={(e) => setAttachment(e.target.files[0])}
            />
            {attachmentError && <span>{attachmentError}</span>}
            </div>
          </div>
          </form>
          
            <a className='mt-4 send_message' onClick={onSubmit} disabled={isLoading}>
              {isLoading ? "Loading..." : "Submit"}
            </a>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            
          </div>
        </div>
      </div>

    </section>

    <section id="about" className="about section light-background">

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row align-items-xl-center gy-5">

          <div className="col-xl-5 content">
            <h3>About Us</h3>
            <h2>We provide the best and high-quality assignment services in Canada</h2>
            <p>We at assignmentservicecanada.com aim to provide high quality academic services across the globe. We deal in all the services ranging from online exam help, homework help, research paper writing help, study help to online quiz help at a wider scale. Each of these services are accounted to our experts who possess the knowledge and competency in these specific areas, and help the students to achieve outstanding results.             </p>
            <a href="#" className="read-more"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
          </div>

          <div className="col-xl-7">
            <div className="row gy-4 icon-boxes">

              <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box">
                  <i className="bi bi-buildings"></i>
                  <p>We provide high quality solutions for the assignments in varied subjects at competitive prices.                  </p>
                </div>
              </div> 

              <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                <div className="icon-box">
                  <i className="bi bi-clipboard-pulse"></i>
                  <p>Our experts are analytical and critical thinkers and provide remarkably efficient assignment solutions.                  </p>
                </div>
              </div>

              <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                <div className="icon-box">
                  <i className="bi bi-command"></i>
                  <p>We offer high quality assignment solutions for more than 35 subjects</p>
                </div>
              </div> 

              <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                <div className="icon-box">
                  <i className="bi bi-graph-up-arrow"></i>
                  <p>We ensure robust plagiarism and integrity in our assignment solutions, keeping in mind the student’s academic growth                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </section>

    
    {/* <section id="stats" className="stats section dark-background">

      <img src={stats} alt="" data-aos="fade-in" />

      <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">

        <div className="row gy-4">

          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
              <p>Clients</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
              <p>Projects</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" className="purecounter"></span>
              <p>Hours Of Support</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter"></span>
              <p>Workers</p>
            </div>
          </div>

        </div>

      </div>

    </section> */}

   
    <section id="services" className="services section">

      
      <div className="container section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
      </div>

      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="100">
            <div className="service-item d-flex">
              <div className="icon flex-shrink-0"><i className="bi bi-briefcase"></i></div>
              <div>
                <h4 className="title"><a href="services-details.html" className="stretched-link">Dissertation</a></h4>
                <p className="description">The dissertation team at Assignment Service Canada is proficient in providing outstanding dissertation services by ensuring that deep research from reliable sources is done to complete the dissertation proposal while aligning to the university standards. </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="200">
            <div className="service-item d-flex">
              <div className="icon flex-shrink-0"><i className="bi bi-card-checklist"></i></div>
              <div>
                <h4 className="title"><a href="services-details.html" className="stretched-link">Essay</a></h4>
                <p className="description">At Assignment Service Canada, we offer essay writing help in fields like MBA, CDR, Computer Science, Networking and Programming through our competent and skilled writers with long experience in essay writing.                 </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="300">
            <div className="service-item d-flex">
              <div className="icon flex-shrink-0"><i className="bi bi-bar-chart"></i></div>
              <div>
                <h4 className="title"><a href="services-details.html" className="stretched-link">Programming</a></h4>
                <p className="description">The programming experts at Assignmentservicecanada.com offers programming help in creating an efficient programming assignment and assisting in development of Android application with 24x7 availability and robust quality check.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="400">
            <div className="service-item d-flex">
              <div className="icon flex-shrink-0"><i className="bi bi-binoculars"></i></div>
              <div>
                <h4 className="title"><a href="services-details.html" className="stretched-link">Editing & Proofreading </a></h4>
                <p className="description">We offer Canada’s best and efficient editing & proofreading services for exhibitions, scientific articles, newspaper articles, student’s homework etc through selective and proficient proof readers. </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="500">
            <div className="service-item d-flex">
              <div className="icon flex-shrink-0"><i className="bi bi-brightness-high"></i></div>
              <div>
                <h4 className="title"><a href="services-details.html" className="stretched-link">Networking</a></h4>
                <p className="description">We provide most efficient networking services by offering robust and error-free assignment solutions while catering to all quality parameters proactively.                 </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="600">
            <div className="service-item d-flex">
              <div className="icon flex-shrink-0"><i className="bi bi-calendar4-week"></i></div>
              <div>
                <h4 className="title"><a href="services-details.html" className="stretched-link">Research</a></h4>
                <p className="description">The experts at Assignmentservicecanada.com are proficient in collecting the necessary information from reliable sources in order to create a well-versed research paper for the students to attain outstanding grades.                </p>
              </div>
            </div>
          </div>

        </div>

      </div>

    </section>

   
    {/* <section id="features" className="features section">

    
      <div className="container section-title" data-aos="fade-up">
        <h2>Features</h2>
        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
      </div>

      <div className="container">

        <div className="row gy-4 align-items-center features-item">
          <div className="col-lg-5 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
            <h3>Corporis temporibus maiores provident</h3>
            <p>
              Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.
            </p>
            <a href="#" className="btn btn-get-started">Get Started</a>
          </div>
          <div className="col-lg-7 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
            <div className="image-stack">
              <img src={feature1} alt="" className="stack-front" />
              <img src={feature2} alt="" className="stack-back" />
            </div>
          </div>
        </div>

        <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div className="col-lg-6 d-flex align-items-center features-img-bg" data-aos="zoom-out">
            <img src={feature3} className="img-fluid" alt="" />
          </div>
          <div className="col-lg-5 d-flex justify-content-center flex-column" data-aos="fade-up">
            <h3>Sunt consequatur ad ut est nulla</h3>
            <p>Cupiditate placeat cupiditate placeat est ipsam culpa. Delectus quia minima quod. Sunt saepe odit aut quia voluptatem hic voluptas dolor doloremque.</p>
            <ul>
              <li><i className="bi bi-check"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></li>
              <li><i className="bi bi-check"></i><span> Duis aute irure dolor in reprehenderit in voluptate velit.</span></li>
              <li><i className="bi bi-check"></i> <span>Facilis ut et voluptatem aperiam. Autem soluta ad fugiat</span>.</li>
            </ul>
            <a href="#" className="btn btn-get-started align-self-start">Get Started</a>
          </div>
        </div>

      </div>

    </section> */}

<section id="faq" className="faq section">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="content px-xl-5">
              <h3>
                <span>Frequently Asked </span><strong>Questions</strong>
              </h3>
            </div>
          </div>

          <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
            <div className="faq-container">
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}
                >
                  <h3 onClick={() => toggleFAQ(index)}>
                    <span className="num">{faq.num}.</span> <span>{faq.question}</span>
                  </h3>
                  <div
                    className="faq-content"
                    style={{
                      display: activeIndex === index ? 'block' : 'none',
                    }}
                  >
                    <p>{faq.answer}</p>
                  </div>
                  {/* Toggle icon click */}
                  <i
                    className={`faq-toggle bi ${
                      activeIndex === index ? 'bi-chevron-down' : 'bi-chevron-right'
                    }`}
                    onClick={() => toggleFAQ(index)}
                  ></i>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="testimonials" className="testimonials section light-background">

      <div className="container">

        <div className="row align-items-center">

          <div className="col-lg-5 info" data-aos="fade-up" data-aos-delay="100">
            <h3>What Our Clients Say</h3>
            <p>
            At Assignment service canada, we take pride in delivering top-quality assignment help to students across Canada. Don’t just take our word for it—hear what our satisfied clients have to say! From fast turnarounds to exceptional content, our team is committed to providing reliable, professional, and affordable assistance that helps students succeed academically. Here are a few testimonials from those who have experienced our services firsthand:
            </p>
          </div>

          <div className="col-lg-7" data-aos="fade-up" data-aos-delay="200">
          <Carousel showArrows={true} showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}>
      
            <div className="testimonial-item">
              <div className="d-flex">
                <img src={user} className="testimonial-img flex-shrink-0" alt="Jessica W." />
                <div>
                  <h3>Jessica W., Toronto</h3>
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className="bi bi-star-fill"></i>
                    ))}
                  </div>
                </div>
              </div>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>I was overwhelmed with my university assignments, but this service came to my rescue! The quality was top-notch, and they delivered everything on time. I couldn't have asked for better help. Highly recommend them to anyone needing academic assistance!</span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>

            <div className="testimonial-item">
              <div className="d-flex">
                <img src={user} className="testimonial-img flex-shrink-0" alt="Mark P." />
                <div>
                  <h3>Mark P., Vancouver</h3>
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className="bi bi-star-fill"></i>
                    ))}
                  </div>
                </div>
              </div>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>I had an urgent paper due in less than 12 hours, and I thought there was no way I could make it. These guys worked wonders and delivered an excellent paper way before the deadline. I'm beyond impressed!</span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>

            <div className="testimonial-item">
              <div className="d-flex">
                <img src={user} className="testimonial-img flex-shrink-0" alt="Natalie R." />
                <div>
                  <h3>Natalie R., Montreal</h3>
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className="bi bi-star-fill"></i>
                    ))}
                  </div>
                </div>
              </div>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>The assignment was not only well-researched but also formatted perfectly to match my professor’s requirements. I’ve used other services before, but none of them delivered the level of quality I received here.</span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>

            <div className="testimonial-item">
              <div className="d-flex">
                <img src={user} className="testimonial-img flex-shrink-0" alt="Matt Brandon" />
                <div>
                  <h3>Matt Brandon</h3>
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className="bi bi-star-fill"></i>
                    ))}
                  </div>
                </div>
              </div>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>From the moment I reached out for help, their customer support team was incredibly responsive. They guided me through the process and made sure everything was done just as I requested. I'll definitely use their service again.</span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>

            <div className="testimonial-item">
              <div className="d-flex">
                <img src={user} className="testimonial-img flex-shrink-0" alt="John Larson" />
                <div>
                  <h3>John Larson</h3>
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className="bi bi-star-fill"></i>
                    ))}
                  </div>
                </div>
              </div>
              <p>
                <i className="bi bi-quote quote-icon-left"></i>
                <span>I’m always concerned about the price when it comes to these services, but this one offered the best value for money without compromising quality. The assignments were well-written, and I got excellent grades!</span>
                <i className="bi bi-quote quote-icon-right"></i>
              </p>
            </div>

          </Carousel>

          </div>

        </div>

      </div>

    </section>

    <section id="recent-posts" className="recent-posts section">

      <div className="container section-title" data-aos="fade-up">
        <h2>Recent Posts</h2>
        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
      </div>

      <div className="container">

        <div className="row gy-4">

          <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <article>

              <div className="post-img">
                <img src={blog1} alt="" className="img-fluid" />
              </div>

              <p className="post-category">Politics</p>

              <h2 className="title">
                <a href="blog-details.html">Dolorum optio tempore voluptas dignissimos</a>
              </h2>

              <div className="d-flex align-items-center">
                <img src={blogauther} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Maria Doe</p>
                  <p className="post-date">
                    <time dateTime="2022-01-01">Jan 1, 2022</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <article>

              <div className="post-img">
                <img src={blog1} alt="" className="img-fluid" />
              </div>

              <p className="post-category">Sports</p>

              <h2 className="title">
                <a href="blog-details.html">Nisi magni odit consequatur autem nulla dolorem</a>
              </h2>

              <div className="d-flex align-items-center">
                <img src={team1} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Allisa Mayer</p>
                  <p className="post-date">
                    <time dateTime="2022-01-01">Jun 5, 2022</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <article>

              <div className="post-img">
                <img src={blog1} alt="" className="img-fluid" />
              </div>

              <p className="post-category">Entertainment</p>

              <h2 className="title">
                <a href="blog-details.html">Possimus soluta ut id suscipit ea ut in quo quia et soluta</a>
              </h2>

              <div className="d-flex align-items-center">
                <img src={blogauther} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author">Mark Dower</p>
                  <p className="post-date">
                    <time dateTime="2022-01-01">Jun 22, 2022</time>
                  </p>
                </div>
              </div>

            </article>
          </div>

        </div>

      </div>

    </section>
<ToastContainer/>
    <section id="contact" className="contact section">

      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">

        <div className="row gy-4">

          <div className="col-lg-6">

            <div className="row gy-4">
              
              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="400">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>supprot@assignmentservicecanada.com</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="500">
                  <i className="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Sunday</p>
                  <p>24 Hours</p>
                </div>
              </div>

            </div> 
          </div>

          <div className="col-lg-6">
            <form onSubmit={handleSubmit} method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
              <div className="row gy-4">
              <div className="col-md-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-md-6">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="col-12">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="6"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>

                  <div className="col-12 text-center">
                    {loading && <div className="loading">Loading...</div>}
                    {error && <div className="error-message">{error}</div>}
                    {success && <div className="sent-message">{success}</div>}
                    <button type="submit" className="btn btn-primary">Send Message</button>
                  </div>

              </div>
            </form>
          </div>

        </div>

      </div>

    </section>
  </main>

  <footer id="footer" className="footer position-relative light-background">

    <div className="container footer-top">
      <div className="row gy-4">
        <div className="col-lg-5 col-md-12 footer-about">
          <a href="index.html" className="logo d-flex align-items-center">
            <span className="sitename">Assignment Service Canada</span>
          </a>
        </div>

        <div className="col-lg-2 col-6 footer-links">
          <h4 style={{textAlign:'justify'}}>Useful Links</h4>
          <ul>
            <li><a href="index.html#hero">Home</a></li>
            <li><a href="index.html#about">About us</a></li>
            <li><a href="index.html#services">Services</a></li>
          </ul>
        </div>

        <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
          <h4>Contact Us</h4>
          <p><strong>Email:</strong> <span>supprot@assignmentservicecanada.com</span></p>
        </div>

      </div>
    </div>

    <div className="container copyright text-center mt-4">
      <p>© <span>Copyright</span> <strong className="sitename">Assignment Service Canada</strong> <span>All Rights Reserved</span></p>
    </div>

  </footer>

  <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>


  <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="assets/vendor/php-email-form/validate.js"></script>
  <script src="assets/vendor/aos/aos.js"></script>
  <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
  <script src="assets/vendor/imagesloaded/imagesloaded.pkgd.min.js"></script>
  <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>

  <script src="assets/js/main.js"></script>

    </div>
  );
}

export default App;
